import React from 'react';
import './terms-and-conditions.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Annotation from 'src/components/annotation';
import Layout from '../components/layout';
import { TranslatedBlock } from '../components/language';

export default class TermsAndConditions extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setPage('terms-and-conditions');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'Terms and Conditions',
          french: "Conditions d'utilisation",
        }}
        showTab={true}
      >
        <TranslatedBlock language="english">
          <div className={'terms-and-conditions'}>
            <h2>Roche Canada Website Terms and Conditions</h2>
            <p>Welcome to the Hoffmann-La Roche Limited (“Roche”, “Us”, “Our” or “We) website.</p>
            <p>
              These Terms and Conditions (“Terms”) are a legal contract between You and Us (collectively, "Everyone")
              and govern Your use of all the text, data, information, software, graphics, photographs and more (all of
              which We refer to as “Materials”) that We and Our affiliates may make available to You, as well as any
              services (“Services”) We may provide through any of Our websites (all of which are referred to in these
              Terms as this “Website”).
            </p>
            <p>
              READ THESE TERMS CAREFULLY BEFORE BROWSING THIS WEBSITE. By using this website, You agree to these Terms.
              If You do not agree, please exit and disregard the information contained herein.
            </p>
            <b>Users</b>
            <p>
              The Materials and Services are intended for residents of Canada only. We take no responsibility for users
              accessing Materials and Services outside of Canada.
            </p>
            <b>Changes</b>
            <p>
              We may alter the Materials and Services We offer You and/or choose to modify, suspend or discontinue this
              Website at any time and without notifying You. We may also change, update, add or remove provisions
              (collectively, “modifications”) of these Terms from time to time.
            </p>
            <p>
              If You object to any such modifications, Your only remedy is to cease using this Website. Continued use of
              this Website following notice of any such modifications indicates You acknowledge and agree to be bound by
              the modifications. Also, please know that these Terms may be replaced by legal notices or terms located on
              particular pages of this Website, as it may state in those notices or terms. These legal notices or terms
              are incorporated into these Terms and are paramount to the provision(s) of these Terms.
            </p>
            <b>License to Use</b>
            <p>
              We grant you a limited, personal, non-exclusive and non-transferable license to use and to display the
              Materials for Your personal, non-commercial use (“Permitted Purposes”). You have no other rights in this
              Website or any Materials and You may not modify, edit, copy, reproduce, create derivative works of,
              reverse engineer, alter, enhance or in any way exploit any of this Website or Materials in any way. If You
              make copies of any of this Website for Permitted Purposes, then We ask that, on all copies, You be sure to
              keep all of Our copyright and other proprietary notices as they appear on this Website.
            </p>
            <b>Privacy</b>
            <p>
              Please refer to Roche's Privacy Policy (incorporated into these Terms by reference) for information on how
              Roche protects personal information about You.
            </p>
            <b>Third Party Content and Links to Other Websites</b>
            <p>
              This Website may contain third party owned content (e.g. articles, data feeds, etc.) and/or links to other
              websites maintained by third parties over whom Roche has no control. Such content and links are provided
              merely as a convenience to users of this Website. Roche does not endorse and assumes no responsibility for
              the content of such websites and makes no representations as to the accuracy or completeness of any
              information contained on such sites. Roche does not accept any liability arising out of any allegation
              that any third party owned content (whether published on this, or any other, website) infringes the
              intellectual property rights of any person or any liability arising out of any information or opinion
              contained on such third party website or content.
            </p>
            <b>Unauthorized Activities</b>
            <p>
              We authorize Your use of this Website only for Permitted Purposes. Any other use of this Website beyond
              the Permitted Purposes is prohibited and unauthorized.
            </p>
            <p>
              Unauthorized use of this Website may result in violation of various Canadian and international copyright
              laws. For clarity, unless You have written permission from Us, You are not authorized to use this Website
              in any of the following ways (these are examples only and the list below is not a complete list of
              everything that You are not permitted to do):
            </p>
            <ul>
              <li>
                For any public or commercial purpose which includes use of this Website on another site or through a
                networked computer environment;
              </li>
              <li>
                In a manner that modifies, publicly displays, publicly performs, reproduces or distributes any of this
                Website;
              </li>
              <li>
                In a manner that violates any local, provincial, national, foreign, or international statute,
                regulation, rule, order, treaty, or other law;
              </li>
              <li>To stalk, harass, or harm another individual;</li>
              <li>
                To impersonate any person or entity or otherwise misrepresent Your affiliation with a person or entity;
              </li>
              <li>To interfere with or disrupt this Website or servers or networks connected to this Website;</li>
              <li>
                To use any data mining, robots, or similar data gathering or extraction methods in connection with this
                Website; or
              </li>
              <li>
                Attempt to gain unauthorized access to any portion of this Website or any other accounts, computer
                systems, or networks connected to this Website, whether through hacking, password mining, or any other
                means.
              </li>
            </ul>
            <p>
              You agree to hire lawyers to defend Us if You violate these Terms and that violation results in a problem
              for Us. You also agree to pay any damages that We may end up having to pay as a result of Your violation.
              You alone are responsible for any violation of these Terms by You. We reserve the right to assume the
              exclusive defense and control of any matter otherwise subject to indemnification by You and, in such case,
              You agree to cooperate with Our defense of such claim.
            </p>
            <p>
              We reserve the right to suspend or terminate Your access to all or part of this website, at any time,
              without notice to You, We believe, that You have breached or may breach any term or condition of this
              agreement, or for its convenience.
            </p>
            <b>Proprietary Rights</b>
            <p>
              Unless otherwise indicated, all logos, designs, and marks on this website are trademarks or service marks
              owned or used under license by Roche. All product names printed in capitals or otherwise appropriately
              marked on this website are trademarks of their respective owners.
            </p>
            <p>
              Unless otherwise specified in these Terms, all Materials, including the arrangement of them on this
              Website, are Our sole property. All rights not expressly granted in these terms are reserved. Except as
              otherwise required or limited by applicable law, any reproduction, distribution, modification,
              retransmission, or publication of any copyrighted material is strictly prohibited without the express
              written consent of the copyright owner or licensee.
            </p>
            <b>Disclaimer of Warranties</b>
            <p>
              THIS WEBSITE IS PROVIDED "AS IS" AND "WITH ALL FAULTS" AND THE ENTIRE RISK AS TO THE QUALITY AND
              PERFORMANCE OF THIS WEBSITE IS WITH YOU.
            </p>
            <p>
              WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND (EXPRESS, IMPLIED OR STATUTORY) WITH RESPECT TO THIS
              WEBSITE, WHICH INCLUDES BUT IS NOT LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, AND
              ACCURACY OR TIMELINESS OF THE INFORMATION PROVIDED.
            </p>
            <p>
              THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE WEBSITE IS FREE OF PROBLEMS. Without limiting what we just
              stated, We make no warranty that this Website will meet Your requirements or that this Website will be
              uninterrupted, timely, secure, or error free or that defects in this Website will be corrected. We make no
              warranty as to any results that come from the use of this Website or as to the accuracy or reliability of
              any information obtained through this Website. o advice or information, whether oral or written, obtained
              by You through this Website or from Us or Our subsidiaries/other affiliated companies shall create any
              warranty. We disclaim all equitable indemnities.
            </p>
            <p>
              While there may be information contained within the Website or Services related to certain medical
              conditions and/or their treatment, should a medical condition exist, consult with Your own physician or
              other health care professional. THE CONTENT AVAILABLE THROUGH THE WEBSITE, OR COMMUNICATED TO YOU DIRECTLY
              THROUGH THE SERVICES, IS FOR INFORMATIONAL AND EDUCATIONAL PURPOSES ONLY AND IS NOT A SUBSTITUTE FOR
              MEDICAL JUDGMENT, ADVICE, DIAGNOSIS, OR TREATMENT OF ANY HEALTH CONDITION OR PROBLEM. You should not rely
              on information contained via the Website for diagnosing, treating, curing, preventing, managing or
              otherwise addressing health problems. Questions should be addressed to a physician or other health care
              professional. WE DO NOT, THROUGH THE WEBSITE OR OTHERWISE, PROVIDE MEDICAL ADVICE, MEDICAL PRESCRIPTIONS,
              TREATMENTS OR DIAGNOSTIC SERVICES. Your doctor or other health care professional, as a learned
              intermediary, is in the best position to assess, and provide information, about Your medical condition and
              any treatment options. In using the Website or Services, You agree that We are not, or will not be, liable
              or otherwise responsible for any decision made or any action taken or any action not taken due to Your use
              of the Website or Services. PLEASE CONSULT YOUR PHYSICIAN OR OTHER HEALTHCARE PROVIDER FOR ALL MEDICAL
              ADVICE OR QUESTIONS.
            </p>
            <b>Limitation of Liability</b>
            <p>
              WE WILL NOT BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM YOUR DISPLAYING, COPYING, USING, OR
              DOWNLOADING ANY MATERIALS TO OR FROM THIS WEBSITE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN
              NO EVENT WILL WE BE LIABLE TO YOU FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL,
              INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC
              ADVANTAGE) HOWEVER ARISING, EVEN IF WE KNOW THERE IS A POSSIBILITY OF SUCH DAMAGE.
            </p>
            <b>Feedback</b>
            <p>
              Any submissions by You to Us (e.g., comments, questions, suggestions, materials – collectively,
              “Feedback”) no matter how submitted, (e.g., call, fax, email) will be treated as both non-confidential and
              non-proprietary. You hereby assign all right, title, and interest in, and We are free to use, without any
              attribution or compensation to You, any ideas, know-how, concepts, techniques, or other intellectual
              property and proprietary rights contained in the Feedback, whether or not patentable, for any purpose
              whatsoever, including but not limited to, developing, manufacturing, having manufactured, licensing,
              marketing, and selling, directly or indirectly, products and services using such Feedback. You understand
              and agree that We are not obligated to use, display, reproduce, or distribute any such ideas, know-how,
              concepts, or techniques contained in the Feedback, and You have no right to compel such use, display,
              reproduction, or distribution.
            </p>
            <b>General</b>
            <p>
              Applicable Ontario and Canadian federal law, without regard to the choice or conflicts of law provisions,
              will govern these Terms. Foreign laws do not apply. Any disputes relating to these Terms or this Website
              will be heard in the courts located in Toronto, Canada. If any of these Terms are deemed inconsistent with
              applicable law, then such term(s) shall be interpreted to reflect the intentions of the parties, and no
              other terms will be modified. By choosing not to enforced any of these Terms, We are not waiving Our
              rights. These Terms are the entire agreement between You and Us and, therefore, supersede all prior or
              contemporaneous negotiations, discussions or agreements between Everyone about this Website. The
              proprietary rights, disclaimer of warranties, representations made by You, indemnities, limitations of
              liability and general provisions shall survive any termination of these Terms.
            </p>
            <b>Contact Us</b>
            <p>
              If You have any questions about these Terms or otherwise need to contact Us for any reason, You can reach
              Us at Hoffmann-La Roche Limited, 7070 Mississauga Road, Mississauga, Ontario or by phone at
              1-800-561-1759.
            </p>
            <p>Last Updated 2018-07-11</p>
          </div>
        </TranslatedBlock>
        <TranslatedBlock language="french">
          <div className={'terms-and-conditions'}>
            <h2>Conditions d’utilisation du site Web de Roche Canada</h2>
            <p>Bienvenue dans le site Web de Hoffmann-La Roche Limitée (« Roche », « nous » ou « notre »).</p>
            <p>
              Les présentes conditions d’utilisation (les « conditions d’utilisation ») constituent un contrat juridique
              entre vous et nous (collectivement, « tous les intéressés ») régissant votre utilisation de l’ensemble des
              textes, des données, des renseignements, des logiciels, des graphiques, des photographies, etc.
              (collectivement, les « documents ») que nous et nos sociétés affiliées mettons à votre disposition, ainsi
              que l’un ou l’autre des services (les « services ») que nous pourrions vous fournir par l’intermédiaire de
              l’un ou l’autre de nos sites Web (collectivement, « ce site Web »).
            </p>
            <p>
              VEUILLEZ LIRE ATTENTIVEMENT LES CONDITIONS D’UTILISATION CI-DESSOUS AVANT D’UTILISER CE SITE WEB. En
              utilisant ce site Web, vous convenez de respecter ces conditions d’utilisation. Si vous n’acceptez pas ces
              conditions, veuillez quitter ce site Web et ne pas tenir compte des renseignements qu’il contient.
            </p>
            <b>Utilisateurs</b>
            <p>
              Les documents et les services sont destinés exclusivement aux résidents du Canada. Nous déclinons toute
              responsabilité à l’égard des utilisateurs qui accèdent aux documents et aux services de l’extérieur du
              Canada.
            </p>
            <b>Modifications</b>
            <p>
              Nous pouvons, en tout temps et sans vous en aviser, apporter des modifications aux documents et aux
              services que nous vous offrons, ou encore choisir de modifier ce site Web, de suspendre son exploitation
              ou d’y mettre fin. Nous pouvons aussi modifier, mettre à jour, ajouter ou supprimer des stipulations
              (collectivement, les « modifications ») des présentes conditions d’utilisation, s’il y a lieu.
            </p>
            <p>
              Si vous vous opposez à l’une ou l’autre de ces modifications, votre seul recours est de cesser d’utiliser
              ce site Web. En continuant d’utiliser ce site Web après avis de telles modifications, vous attestez avoir
              pris connaissance des modifications et consentez à être lié par celles-ci. Par ailleurs, veuillez noter
              que les présentes conditions d’utilisation peuvent être remplacées par des avis juridiques ou d’autres
              conditions d’utilisation publiées sur certaines pages particulières de ce site Web, comme cela peut être
              indiqué dans ces avis juridiques ou ces conditions d’utilisation. Ces avis juridiques et ces conditions
              d’utilisation sont incorporés aux présentes conditions d’utilisation et ont préséance sur les stipulations
              des présentes.
            </p>
            <b>Licence d’utilisation</b>
            <p>
              Nous vous concédons une licence limitée, personnelle, non exclusive et non transférable vous permettant
              d’utiliser et d’afficher les documents à des fins personnelles et non commerciales (les « fins permises
              »). Vous n’avez aucun autre droit sur ce site Web ni sur l’un ou l’autre des documents, et il vous est
              interdit de modifier, d’éditer, de copier, de reproduire, d’améliorer, d’analyser par rétro-ingénierie ou
              d’exploiter une partie de ce site Web ou des documents ou d’en tirer des œuvres dérivées d’une quelconque
              façon. Si vous faites des copies d’une partie de ce site Web à des fins permises, nous vous demandons de
              veiller à conserver, sur toutes les copies, tous les avis de droit d’auteur et autres avis de droits
              exclusifs tels qu’ils apparaissent sur ce site Web.
            </p>
            <b>Confidentialité</b>
            <p>
              Veuillez consulter l’énoncé de confidentialité de Roche (incorporé à ces conditions d’utilisation par
              renvoi) pour savoir de quelle manière Roche protège les renseignements personnels vous concernant.
            </p>
            <b>Contenu de tiers et liens vers d’autres sites Web</b>
            <p>
              Ce site Web peut contenir des données (p. ex. articles, flux de données, etc.) appartenant à des tiers
              ainsi que des liens vers d’autres sites Web exploités par des tiers sur lesquels Roche n’a aucun contrôle.
              Ce contenu et ces liens ne sont fournis qu’à des fins de commodité pour les visiteurs de ce site Web.
              Roche n’assume aucune responsabilité quant au contenu de ces sites Web, pas plus qu’elle ne fait de
              déclaration quant à l’exactitude et l’exhaustivité de l’information contenue dans de tels sites. Roche
              décline toute responsabilité découlant de toute allégation selon laquelle un contenu appartenant à des
              tiers (qu’il ait été publié sur ce site Web ou sur tout autre site Web) viole les droits de propriété
              intellectuelle d’une personne et toute responsabilité découlant de tout renseignement ou opinion contenus
              dans un tel site Web ou contenu d’un tiers.
            </p>
            <b>Activités non autorisées</b>
            <p>
              Nous vous autorisons à utiliser ce site Web aux fins permises seulement. Tout autre usage de ce site Web
              au-delà des fins permises est interdit et non autorisé.
            </p>
            <p>
              L’utilisation non autorisée de ce site Web peut donner lieu à la violation de différentes lois canadiennes
              et internationales sur le droit d’auteur. Il est entendu que, sauf si vous avez reçu notre permission par
              écrit, vous n’êtes pas autorisé à utiliser ce site Web de l’une ou l’autre des façons suivantes (qui ne
              sont que des exemples, la liste ci-après n’énumérant pas de manière exhaustive tout ce que vous n’avez pas
              la permission de faire)&nbsp;:
            </p>
            <ul>
              <li>
                À toute fin publique ou commerciale, ce qui comprend l’utilisation de ce site Web sur un autre site Web
                ou dans un environnement informatique en réseau;
              </li>
              <li>
                D’une façon qui modifie, affiche publiquement, exécute publiquement, reproduit ou distribue toute partie
                de ce site Web;
              </li>
              <li>
                D’une façon qui enfreint une loi, un règlement, une règle, une ordonnance, un traité ou autre instrument
                juridique émanant d’une autorité locale, provinciale, nationale, étrangère ou internationale;
              </li>
              <li>Pour épier ou harceler une autre personne ou lui nuire;</li>
              <li>
                Pour usurper l’identité de toute personne physique ou morale ou prétendre faussement être affilié à une
                personne physique ou morale;
              </li>
              <li>
                Pour entraver ou gêner le fonctionnement de ce site Web ou des serveurs ou réseaux connectés à ce site
                Web;
              </li>
              <li>
                Pour utiliser des techniques de forage de données, des robots ou des méthodes similaires de collecte ou
                d’extractions de données en lien avec ce site Web; ou
              </li>
              <li>
                Tenter d’accéder sans autorisation à toute partie de ce site Web ou à tout autre compte, système
                informatique ou réseau connecté à ce site Web, que ce soit par voie de piratage, de minage de mots de
                passe ou par tout autre moyen.
              </li>
            </ul>
            <p>
              Vous vous engagez à retenir les services d’avocats pour nous défendre si vous avez violé les présentes
              conditions d’utilisation et si cette violation nous occasionne des problèmes. Vous vous engagez également
              à payer tout dommage-intérêt que nous pourrions être tenus de verser du fait de votre violation. Vous êtes
              seul responsable de toute violation de votre part des présentes conditions d’utilisation. Nous nous
              réservons le droit de prendre en charge la défense et le contrôle exclusifs de toute affaire autrement
              sujette à indemnisation de votre part et, dans un tel cas, vous vous engagez à coopérer avec nous pour la
              défense à l’encontre d’une telle réclamation.
            </p>
            <p>
              Nous nous réservons le droit de suspendre ou de résilier votre accès à la totalité ou à une partie de ce
              site Web, en tout temps et sans vous en aviser, si nous sommes d’avis que vous avez violé ou que vous
              pourriez violer une des présentes conditions d’utilisation, ou pour des raisons de commodité.
            </p>
            <b>Droits exclusifs</b>
            <p>
              À moins d’indication contraire, tous les logos, dessins et marques de commerce figurant dans ce site Web
              sont des marques de commerce détenues ou utilisées sous licence par Roche. Tous les noms de produits qui
              sont en majuscules ou autrement identifiés de façon appropriée dans ce site Web sont des marques de
              commerce de leur détenteur respectif.
            </p>
            <p>
              À moins d’indication contraire dans les présentes conditions d’utilisation, tous les documents, y compris
              leur disposition dans ce site Web, sont notre propriété exclusive. Tous les droits qui ne sont pas
              expressément octroyés dans ces conditions d’utilisation sont réservés. Sous réserve des exigences ou des
              limites prévues par la loi applicable, toute reproduction, distribution, modification, retransmission ou
              publication d’un quelconque document protégé par le droit d’auteur est formellement interdite sans le
              consentement exprès par écrit du titulaire du droit d’auteur ou de son licencié.
            </p>
            <b>Exonération de garanties</b>
            <p>
              CE SITE WEB EST PROPOSÉ « EN L’ÉTAT » ET « AVEC TOUS SES DÉFAUTS », ET VOUS ASSUMEZ L’INTÉGRALITÉ DU
              RISQUE QUANT À SA QUALITÉ ET À SON FONCTIONNEMENT.
            </p>
            <p>
              NOUS DÉCLINONS EXPRESSÉMENT TOUTE GARANTIE DE QUELQUE SORTE QUE CE SOIT (EXPLICITE, IMPLICITE OU LÉGALE)
              RELATIVEMENT À CE SITE WEB, CE QUI COMPREND, SANS S’Y LIMITER, TOUTE GARANTIE IMPLICITE OU LÉGALE DE
              QUALITÉ MARCHANDE, D’ADÉQUATION À UNE UTILISATION OU À UNE FIN PARTICULIÈRE, DE TITRE, DE NON-VIOLATION
              DES DROITS DE PROPRIÉTÉ INTELLECTUELLE ET D’EXACTITUDE OU D’ACTUALITÉ DE L’INFORMATION FOURNIE.
            </p>
            <p>
              CELA SIGNIFIE QUE NOUS NE VOUS PROMETTONS PAS QUE CE SITE WEB SERA EXEMPT DE PROBLÈMES. Sans restreindre
              la portée de ce que nous venons d’affirmer, nous ne garantissons nullement que ce site Web répondra à vos
              exigences ou qu’il sera disponible de façon ininterrompue, rapide, sécurisée ou sans erreur, ni que les
              erreurs que contient ce site Web seront corrigées. Nous ne donnons aucune garantie quant aux résultats
              produits par l’utilisation de ce site Web ou quant à l’exactitude ou la fiabilité de toute information
              obtenue par celui-ci. Aucun avis ou renseignement que vous avez obtenu verbalement ou par écrit par ce
              site Web ou auprès de nous ou de l’une de nos filiales ou sociétés affiliées ne saurait créer une
              quelconque garantie. Nous excluons toute indemnité équitable.
            </p>
            <p>
              Le site Web ou les services peuvent contenir certains renseignements se rapportant à certaines maladies ou
              à leur traitement. Toutefois, si vous avez un problème de santé, vous devez consulter votre propre médecin
              ou un autre professionnel de la santé. LE CONTENU QUI VOUS EST OFFERT PAR LE SITE WEB OU VOUS EST
              COMMUNIQUÉ DIRECTEMENT PAR LES SERVICES NE SERT QU’À DES FINS INFORMATIVES ET ÉDUCATIVES ET NE SAURAIT
              REMPLACER UN JUGEMENT, UN AVIS, UN DIAGNOSTIC OU UN TRAITEMENT DISPENSÉ PAR UN MÉDECIN RELATIVEMENT À TOUT
              PROBLÈME DE SANTÉ. Vous ne devez pas vous fier à l’information présentée sur ce site Web pour
              diagnostiquer, traiter, guérir, prévenir ou prendre en charge un problème de santé ou y faire face d’une
              autre façon. Les questions doivent être posées à un médecin ou à un autre professionnel de la santé. QUE
              CE SOIT PAR L’INTERMÉDIAIRE DU SITE WEB OU AUTREMENT, NOUS NE DISPENSONS AUCUN CONSEIL, TRAITEMENT OU
              DIAGNOSTIC MÉDICAL ET NOUS NE DÉLIVRONS AUCUNE ORDONNANCE. Votre médecin ou un autre professionnel de la
              santé, en tant qu’intermédiaire compétent, est le mieux placé pour évaluer votre état de santé, vous
              renseigner à ce sujet et vous présenter les options thérapeutiques possibles. En utilisant le site Web ou
              les services, vous convenez que nous ne saurions être tenus responsables de quelque décision ou mesure
              prise ou non prise du fait de votre utilisation du site Web ou des services. POUR TOUTE QUESTION OU
              DEMANDE DE CONSEIL D’ORDRE MÉDICAL, VEUILLEZ VOUS ADRESSER À VOTRE MÉDECIN OU À UN AUTRE PROFESSIONNEL DE
              LA SANTÉ.
            </p>
            <b>Limitation de responsabilité</b>
            <p>
              NOUS DÉCLINONS TOUTE RESPONSABILITÉ ENVERS VOUS SI VOUS AVEZ SUBI UN DOMMAGE APRÈS AVOIR AFFICHÉ, COPIÉ,
              UTILISÉ OU TÉLÉCHARGÉ TOUT DOCUMENT VERS CE SITE WEB OU À PARTIR DE CELUI-CI. DANS TOUTE LA MESURE PERMISE
              PAR LA LOI, NOUS NE SAURIONS EN AUCUN CAS ÊTRE TENUS RESPONSABLES ENVERS VOUS DE TOUS DOMMAGES, QU’ILS
              SOIENT INDIRECTS, EXTRAORDINAIRES, EXEMPLAIRES, PUNITIFS, PARTICULIERS, ACCESSOIRES OU CONSÉCUTIFS (Y
              COMPRIS LA PERTE DE DONNÉES, DE REVENUS, DE BÉNÉFICES, DE JOUISSANCE OU D’UN AUTRE AVANTAGE ÉCONOMIQUE)
              SANS ÉGARD À LEUR CAUSE, MÊME SI NOUS AVONS CONNAISSANCE DE LA POSSIBILITÉ DE TELS DOMMAGES.
            </p>
            <b>Échange</b>
            <p>
              Toute communication que vous nous transmettez (p. ex. commentaires, questions, suggestions, documents –
              collectivement, la « rétroaction ») sans égard à sa voie de transmission (p. ex. téléphone, télécopieur,
              courriel) sera traitée comme une information non confidentielle et non exclusive. Par les présentes, vous
              cédez tous vos droits, titres et intérêts sur l’ensemble des idées, du savoir-faire, des concepts, des
              techniques et autres droits de propriété intellectuelle et droits exclusifs contenus dans la rétroaction,
              que leur objet soit brevetable ou pas, à quelque fin que ce soit, y compris pour mettre au point,
              fabriquer, faire fabriquer, octroyer des licences, mettre en marché et vendre, directement ou
              indirectement, des produits et des services à l’aide d’une telle rétroaction, et nous pouvons les utiliser
              librement, sans vous rétribuer ou vous dédommager. Vous comprenez et convenez que nous ne sommes
              aucunement tenus d’utiliser, d’afficher, de reproduire ou de distribuer ces idées, ce savoir-faire, ces
              concepts ou ces techniques contenus dans la rétroaction et que vous n’avez aucun droit d’exiger leur
              utilisation, leur affichage, leur reproduction ou leur distribution.
            </p>
            <b>General</b>
            <p>
              Les présentes conditions d’utilisation sont régies par les lois de l’Ontario et par les lois fédérales
              canadiennes qui s’y appliquent, sans égard aux dispositions sur le choix de la loi applicable ou sur les
              conflits de lois. Les lois étrangères ne s’appliquent pas. Tout différend concernant ces conditions
              d’utilisation ou ce site Web sera entendu par les tribunaux de Toronto (Canada). Si l’une ou l’autre de
              ces conditions d’utilisation est jugée incompatible avec la loi applicable, elle sera interprétée de façon
              à refléter les intentions des parties, et aucune autre condition ne sera modifiée. Même si nous
              choisissons de ne pas faire appliquer l’une ou l’autre de ces conditions d’utilisation, nous ne renonçons
              aucunement pour autant à nos droits à cet égard. Les présentes conditions d’utilisation représentent
              l’intégralité de l’entente intervenue entre vous et nous et, par conséquent, remplacent toute négociation,
              discussion ou entente, passée ou actuelle, entre tous les intéressés au sujet de ce site Web. Les
              stipulations concernant les droits exclusifs, l’exonération de garanties, les déclarations que vous avez
              faites, les indemnisations et la limitation de la responsabilité, de même que les clauses générales,
              demeureront en vigueur après la résiliation, le cas échéant, de ces conditions d’utilisation.
            </p>
            <b>Pour communiquer avec nous</b>
            <p>
              Pour toute question au sujet de ces conditions d’utilisation ou pour toute autre raison, vous pouvez
              communiquer avec nous à Hoffmann-La Roche Limited/Limitée, 7070 Mississauga Road, Mississauga (Ontario) ou
              au 1-800-561-1759.
            </p>
            <p>Mises à jour le 11 juillet 2018</p>
          </div>
        </TranslatedBlock>
        <Annotation />
      </Layout>
    );
  }
}
